import { render, staticRenderFns } from "./X-Shaped.vue?vue&type=template&id=2bd0e5f7&scoped=true&"
import script from "./X-Shaped.vue?vue&type=script&lang=js&"
export * from "./X-Shaped.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bd0e5f7",
  null
  
)

export default component.exports