<template>
  <g>
    <shape
      :path="figure.shape"
      :is-dashed="false"
      stroke="#333333"
      :stroke-width="2"
      fill="#F6F6F6"
      class="scheme-circuit"
    />
    <g v-if="!isInNav">
      <g :class="{ 'transparent': isSectorPage }">
        <g v-if="withCorrection">
          <template v-for="(l, i) in figure.dashedShape">
            <line
              :x1="l.x1" :x2="l.x2" :y1="l.y1" :y2="l.y2"
              stroke-dasharray="6"
              stroke="#333333"
              stroke-width="1"
              :key="`line--${i}`"/>
          </template>
        </g>
        <shape
          v-if="!withCorrection"
          :path="figure.dashedShape"
          :is-dashed="true"
          stroke="#333333"
          :stroke-width="1"
          fill="none"
        />
        <shape
          :path="figure.angles"
          :is-dashed="true"
          stroke="#333333"
          :stroke-width="1"
          fill="none"
        />
        <shape-size
          v-for="(arrow, i) in arrows"
          :path="arrow.path"
          :value="arrow.value"
          :text="arrow.text"
          :key="`arrows-size--${i}`"
          :scale="figureDrawingParams.scale"
          :rollWidthOptions="rollWidthOptions"
          :delimeter="scaledBiases.sideDelimiter"
        ></shape-size>
        <shape-size
          v-for="(side, i) in sides"
          :path="side.path"
          :value="side.value"
          :text="side.text"
          :key="`side-size--${i}`"
          :scale="figureDrawingParams.scale"
          :rollWidthOptions="rollWidthOptions"
          :withCorrection="withCorrection"
          :delimeter="scaledBiases.sideDelimiter"
        ></shape-size>
        <zone-letter
          v-for="(item, index) in zoneLetters"
          :x="item.x"
          :y="item.y"
          :key="`letter--${item.letter}--${index}`"
        >
          {{ item.letter }}
        </zone-letter>
      </g>
      <g>
        <ruler
          v-for="ruler in 4"
          :x1="0"
          :y1="points[`y${ruler}`]"
          :x2="defaultSvgParams.width"
          :y2="points[`y${ruler}`]"
          :key="`horizontal-ruler--${ruler}`"
        />
      </g>
      <g>
        <ruler
          v-for="ruler in 6"
          :x1="points[`x${ruler}`]"
          :y1="0"
          :x2="points[`x${ruler}`]"
          :y2="defaultSvgParams.height"
          :key="`vertical-ruler--${ruler}`"
        />
      </g>
      <arrow v-if="current.params.a" :path="arrowA" :color="colors.a" />
      <arrow v-if="current.params.b" :path="arrowB" :color="colors.b" />
      <arrow v-if="current.params.f" :path="arrowF" :color="colors.f" />
      <arrow v-if="current.params.c" :path="arrowC" :color="colors.c" />
      <arrow v-if="current.params.d" :path="arrowD" :color="colors.d" />
      <arrow v-if="current.params.e" :path="arrowE" :color="colors.e" />
      <arrow v-if="current.params.i" :path="arrowI" :color="colors.i" />
      <arrow v-if="current.params.g" :path="arrowG" :color="colors.g" />

      <size
        v-if="current.params.a"
        :x="sizeA.x"
        :y="sizeA.y"
        :fill="colors.a"
        :text="`a = ${current.params.a}`"
      />
      <size
        v-if="current.params.b"
        :x="sizeB.x"
        :y="sizeB.y"
        :fill="colors.b"
        :text="`b = ${current.params.b}`"
      />
      <size
        v-if="current.params.f"
        :x="sizeF.x"
        :y="sizeF.y"
        :fill="colors.f"
        :text="`f = ${current.params.f}`"
      />
      <size
        v-if="current.params.c"
        :x="sizeC.x"
        :y="sizeC.y"
        :fill="colors.c"
        :text="`c = ${current.params.c}`"
      />
      <size
        v-if="current.params.d"
        :x="sizeD.x"
        :y="sizeD.y"
        :fill="colors.d"
        :text="`d = ${current.params.d}`"
      />
      <size
        v-if="current.params.e"
        :x="sizeE.x"
        :y="sizeE.y"
        :fill="colors.e"
        :text="`e = ${current.params.e}`"
      />
      <size
        v-if="current.params.g"
        :x="sizeG.x"
        :y="sizeG.y"
        :fill="colors.g"
        :text="`g = ${current.params.g}`"
      />
      <size
        v-if="current.params.i"
        :x="sizeI.x"
        :y="sizeI.y"
        :fill="colors.i"
        :text="`i = ${current.params.i}`"
      />
    </g>
  </g>
</template>

<script>
import {
  drawSizeArrowHorizontal,
  drawSizeArrowVertical
} from '@/utils/drawing'
import Shape from '@/components/dump/Figures/figure-elements/Shape'
import Size from '@/components/dump/Figures/figure-elements/Size'
import Ruler from '@/components/dump/Figures/figure-elements/Ruler'
import Arrow from '@/components/dump/Figures/figure-elements/Arrow'
import ZoneLetter from '@/components/dump/Figures/figure-elements/ZoneLetter'
import ShapeSize from '@/components/dump/Figures/figure-elements/ShapeSize'
import figureCommonSettings from '@/mixins/figureCommonSettings'

export default {
  components: { ShapeSize, ZoneLetter, Arrow, Ruler, Size, Shape },
  props: {
    isSectorPage: {
      type: Boolean,
      default: false
    },
    isInNav: {
      type: Boolean
    },
    buildingHeight: {
      type: Number,
      default: 1,
      required: true
    },
    current: {
      type: Object,
      default: () => ({}),
      required: true
    },
    rollWidthOptions: {
      type: Object,
      default: null
    }
  },
  name: 'X-Shaped',
  mixins: [figureCommonSettings],
  computed: {
    arrowA() {
      const { x1, x5, y1, y2 } = this.points
      const x = x5 < x1 ? x5 - this.arrowsOffset : x1 - this.arrowsOffset
      return drawSizeArrowVertical(x, y1, y2)
    },
    arrowB() {
      const { x1, x5, y2, y3 } = this.points
      const x = x5 < x1 ? x5 - this.arrowsOffset : x1 - this.arrowsOffset
      return drawSizeArrowVertical(x, y2, y3)
    },
    arrowF() {
      const { x1, x5, y3, y4 } = this.points
      const x = x5 < x1 ? x5 - this.arrowsOffset : x1 - this.arrowsOffset
      return drawSizeArrowVertical(x, y3, y4)
    },
    arrowC() {
      const { x5, x2, y4 } = this.points
      const y = y4 + this.arrowsOffset
      return drawSizeArrowHorizontal(x5, y, x2)
    },
    arrowD() {
      const { x2, x3, y4 } = this.points
      const y = y4 + this.arrowsOffset
      return drawSizeArrowHorizontal(x2, y, x3)
    },
    arrowE() {
      const { x3, x6, y4 } = this.points
      const y = y4 + this.arrowsOffset
      return drawSizeArrowHorizontal(x3, y, x6)
    },
    arrowI() {
      const { x3, x4, y1 } = this.points
      const y = y1 - this.arrowsOffset
      return drawSizeArrowHorizontal(x3, y, x4)
    },
    arrowG() {
      const { x1, x2, y1 } = this.points
      const y = y1 - this.arrowsOffset
      return drawSizeArrowHorizontal(x1, y, x2)
    },
    sizeA() {
      const { x1, x5, y1, y2 } = this.points
      return {
        x: x5 < x1 ? x5 - this.arrowsOffset : x1 - this.arrowsOffset,
        y: (y1 + y2) / 2
      }
    },
    sizeB() {
      const { x1, x5, y2, y3 } = this.points
      return {
        x: x5 < x1 ? x5 - this.arrowsOffset : x1 - this.arrowsOffset,
        y: (y2 + y3) / 2
      }
    },
    sizeF() {
      const { x1, x5, y3, y4 } = this.points
      return {
        x: x5 < x1 ? x5 - this.arrowsOffset : x1 - this.arrowsOffset,
        y: (y3 + y4) / 2
      }
    },
    sizeC() {
      const { x5, x2, y4 } = this.points
      return {
        x: (x5 + x2) / 2,
        y: y4 + this.arrowsOffset
      }
    },
    sizeD() {
      const { x2, x3, y4 } = this.points
      return {
        x: (x2 + x3) / 2,
        y: y4 + this.arrowsOffset
      }
    },
    sizeE() {
      const { x3, x6, y4 } = this.points
      return {
        x: (x3 + x6) / 2,
        y: y4 + this.arrowsOffset
      }
    },
    sizeG() {
      const { x1, x2, y1 } = this.points
      return {
        x: (x1 + x2) / 2,
        y: y1 - this.arrowsOffset
      }
    },
    sizeI() {
      const { x3, x4, y1 } = this.points
      return {
        x: (x3 + x4) / 2,
        y: y1 - this.arrowsOffset
      }
    },
    zoneLetters() {
      const { x2, x3, y2, y3 } = this.points

      return this.getLetters(x2, x3, y2, y3)
    }
  }
}
</script>

<style scoped></style>
